import { useEffect } from "react";
import { isInDevMode } from "utils/misc";
import AdPlaceholder from "./AdPlaceholder";

interface AdSenseProps {
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly client: string;
  readonly slot: string;
  readonly layout?: string;
  readonly layoutKey?: string;
  readonly format?: string;
  readonly responsive?: string;
  readonly pageLevelAds?: boolean;
  readonly adTest?: string;
}

const USE_PLACEHOLDERS_IN_DEV = true;

export function AdSenseAd(props: AdSenseProps) {
  const showAds = !(USE_PLACEHOLDERS_IN_DEV && isInDevMode());

  useEffect(() => {
    try {
      if (showAds) {
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
      }
    } catch (e) {
      if (isInDevMode()) {
        console.warn(e);
      }
    }
  }, [showAds]);

  if (!showAds) {
    return <AdPlaceholder style={props.style} />;
  }

  return (
    <ins
      className={`adsbygoogle ${props.className}`}
      style={props.style}
      data-ad-client={props.client}
      data-ad-slot={props.slot}
      data-ad-layout={props.layout}
      data-ad-layout-key={props.layoutKey}
      data-ad-format={props.format}
      data-full-width-responsive={props.responsive}
      data-adtest={props.adTest}
    />
  );
}
