import {
  Button,
  Card,
  Center,
  Container,
  Mark,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Image,
  type TextProps,
  Title,
} from "@mantine/core";

import { Carousel } from "react-responsive-carousel";

import { makePage } from "page_setup/makePage";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ChromeExtensionBadge } from "./BrowserExtensionBadge";
import TextLoop from "./TextLoop";
import { WorkInProgressBanner } from "./WorkInProgressBanner";
import { KofiButton } from "./KofiButton";

import "./LandingPage.css";
import { HackerNewsBadge } from "./HackerNewsBadge";
import { ButtonPanel } from "common_ui/ButtonPanel";
import { AdSenseAd } from "ads/AdSense";

const LandingPageImpl = () => {
  const navigate = useNavigate();
  const smallScreen = useSmallScreen();

  const goToMatrix = useCallback(() => {
    navigate("/distance-matrix");
  }, [navigate]);

  const goToHeatmap = useCallback(() => {
    navigate("/heatmap/create");
  }, [navigate]);

  const goToSavedHeatmap = useCallback(() => {
    navigate("/heatmap/list");
  }, [navigate]);

  const textProps: TextProps = {
    size: smallScreen ? "lg" : "xl",
    className: "landing-page-glowing-text",
  };

  return (
    <div className="w100 h100 landing-page-parent">
      <div className="solid-landing-page-background" />
      <img
        className="landing-page-city-background"
        src={require("media/SkyBackground.jpg")}
        onLoad={(e) => (e.currentTarget.style.opacity = "1")}
        alt="City Landscape"
      />

      <WorkInProgressBanner />
      <Center w="100%">
        <AdSenseAd
          client={"ca-pub-3826030519001321"}
          slot="6030411679"
          format="inline"
          style={{ display: "inline-block", width: smallScreen ? 300 : 700, height: 70 }}
        />
      </Center>

      <Container className="landing-page-main-content" c={"black"}>
        {!smallScreen && (
          <div className="hacker-news-badge">
            <HackerNewsBadge />
          </div>
        )}

        <Stack className="h100">
          <Space h="lg" />
          <Title order={1} className="landing-page-glowing-text">
            <TextLoop timeout={3000}>
              <Mark>Moving</Mark>
              <Mark>Booking that hotel</Mark>
              <Mark>Picking your Airbnb</Mark>
              <Mark>Apartment hunting</Mark>
            </TextLoop>
            <br />
            just became easier.
          </Title>

          <Text {...textProps}>
            There to Where helps you evaluate listings based on their proximity to things{" "}
            <Mark>you</Mark> care about.
          </Text>

          <div style={{ width: "100%", display: "flex", justifyContent: "center", height: "auto" }}>
            <div style={{ width: smallScreen ? "100%" : "90%" }}>
              <Carousel
                autoPlay
                interval={3000}
                animationHandler={"fade"}
                infiniteLoop
                showThumbs={false}
                stopOnHover={false}
                showIndicators={false}
                swipeable={false}
              >
                <Image radius="md" src={require("media/HeatmapScreenshot.jpg")} />
                <Image radius="md" src={require("media/DistanceMatrixScreenshot.jpg")} />
                <Image radius="md" src={require("media/WebExtensionScreenshot.jpg")} />
              </Carousel>
            </div>
          </div>

          <Text {...textProps}>
            No more juggling of Google Maps tabs.
            <br />
            No more "Arg! I forgot this is far from work" moments.
            <br />
            No more{" "}
            <Text italic span>
              frustration.
            </Text>
            <br />
          </Text>

          <Text {...textProps}>
            <Mark>Handle everything in one page.</Mark>
          </Text>

          <Text {...textProps}>
            Don't have a specific place in mind? No worries. We can help you determine the best
            places in your city to start looking.
          </Text>

          <SimpleGrid cols={smallScreen ? 1 : 2}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Stack h={"100%"}>
                <Text fw={500}>
                  Wondering how far a listing is from work, your friends, that gym, maybe some chess
                  clubs, and your favorite grocery chain?
                </Text>
                <div className="flex-1" />
                <Button color={"grape"} onClick={goToMatrix}>
                  Check out our Distance Matrix
                </Button>
              </Stack>
            </Card>

            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Stack h={"100%"}>
                <Text fw={500}>
                  Wondering which parts of your city fit your preferences the most?
                </Text>
                <div className="flex-1" />
                <Button color={"grape"} onClick={goToHeatmap}>
                  Make a City Heatmap
                </Button>
                <Button color={"grape"} variant="outline" onClick={goToSavedHeatmap}>
                  See your Saved Heatmaps
                </Button>
              </Stack>
            </Card>
          </SimpleGrid>
        </Stack>

        <Space h="sm" />
        <Center className="flex-col">
          <ChromeExtensionBadge />
          <Space h="sm" />
          <ButtonPanel>
            <KofiButton />
            {smallScreen && (
              <>
                <Space h="sm" />
                <HackerNewsBadge />
              </>
            )}
          </ButtonPanel>
        </Center>
      </Container>
    </div>
  );
};

export const LandingPage = makePage(LandingPageImpl, undefined, { showTitle: true });
