export const AdPlaceholder = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <div
      style={{
        ...style,
        backgroundColor: "#f0f0f0",
        border: "2px dashed #ccc",
        borderRadius: "8px",
        color: "#666",
        fontSize: "14px",
        fontWeight: "bold",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      This is an ad placeholder
    </div>
  );
};

export default AdPlaceholder;
