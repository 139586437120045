import { Box, Button, Space, Text, Title } from "@mantine/core";
import { ErrorBoundary } from "common_ui/ErrorBoundary";
import { StandardPanelResizeHandle } from "common_ui/StandardPanelResizeHandle";
import { EmbeddedMap } from "geomasters/maps/EmbeddedMap";
import { makePage, TAB_NAME_BASE } from "page_setup/makePage";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import {
  AddressSpecificQuery,
  OpenQuery,
  SerializedQueryType,
  type Query,
} from "pages/distance_matrix/locationQueryTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import { getPreferredModeOfTransport } from "storage/localStorageAccessors";
import { usePreferredModeOfTransport } from "storage/localStorageHooks";
import { TransportationMode } from "types/geo";
import type { Undefined } from "types/utils";
import { useBooleanState } from "utils/hooks";
import { SalesDemoQueryListElement } from "./SalesDemoQueryListItem";

import { LoadingIndicator } from "common_ui/LoadingIndicator";
import { TouchFriendlyTooltip } from "common_ui/TouchFriendlyTooltip";
import { GeoMasterName } from "geomasters/geomaster";
import { FiInfo } from "react-icons/fi";
import { assertDefined } from "utils/misc";
import { sleep } from "utils/promises";
import "./SalesDemoDistanceMatrixPage.css";

const REFERENCE_URL = "https://streeteasy.com/building/390-lafayette-avenue-brooklyn/1?from_map=1";

const REFERENCE_PLACE = new AddressSpecificQuery({
  type: SerializedQueryType.ADDRESS_SPECIFIC,
  location: {
    name: "390 Lafayette Avenue #1",
    timestampInMillis: Date.now(),
    coords: {
      longitude: -73.959648,
      latitude: 40.688713,
    },
    geoMasterName: GeoMasterName._DUMMY_,
  },
  queryBaseVersion: 1,
  addressQueryVersion: 1,
});

const WORK = new AddressSpecificQuery({
  type: SerializedQueryType.ADDRESS_SPECIFIC,
  location: {
    name: "Work",
    timestampInMillis: Date.now(),
    coords: {
      longitude: -74.00286293996842,
      latitude: 40.71514811822874,
    },
    geoMasterName: GeoMasterName._DUMMY_,
  },
  queryBaseVersion: 1,
  addressQueryVersion: 1,
});

const PROSPECT_PARK = new AddressSpecificQuery({
  type: SerializedQueryType.ADDRESS_SPECIFIC,
  location: {
    name: "Prospect Park",
    timestampInMillis: Date.now(),
    coords: {
      longitude: -73.9645,
      latitude: 40.6686,
    },
    geoMasterName: GeoMasterName._DUMMY_,
  },
  queryBaseVersion: 1,
  addressQueryVersion: 1,
});

const BROOKLYN_MUSEUM = new AddressSpecificQuery({
  type: SerializedQueryType.ADDRESS_SPECIFIC,
  location: {
    name: "Brooklyn Museum",
    timestampInMillis: Date.now(),
    coords: {
      longitude: -73.9636,
      latitude: 40.6712,
    },
    geoMasterName: GeoMasterName._DUMMY_,
  },
  queryBaseVersion: 1,
  addressQueryVersion: 1,
});

const HOLLOW_NICKEL_BAR = new AddressSpecificQuery({
  type: SerializedQueryType.ADDRESS_SPECIFIC,
  location: {
    name: "Hollow Nickel Bar",
    timestampInMillis: Date.now(),
    coords: {
      longitude: -73.981685,
      latitude: 40.685503,
    },
    geoMasterName: GeoMasterName._DUMMY_,
  },
  queryBaseVersion: 1,
  addressQueryVersion: 1,
});

const TRADER_JOS = new OpenQuery({
  type: SerializedQueryType.OPEN,
  queryBaseVersion: 1,
  openQueryVersion: 1,
  textQuery: "Trader Joe's",
});

const SalesDemoDistanceMatrixPageImpl = () => {
  const [userSpecificQueries] = useState<Array<Query>>([WORK, PROSPECT_PARK]);
  const [listingGeneralQueries] = useState<Array<Query>>([
    TRADER_JOS,
    HOLLOW_NICKEL_BAR,
    BROOKLYN_MUSEUM,
  ]);
  const [isLoading, setIsLoading, setIsNotLoading] = useBooleanState(true);
  const [queryForSidepanel, setQueryForSidepanel] = useState<Undefined<Query>>();
  const [, setPreferredTransportationMode] = usePreferredModeOfTransport();
  const smallScreen = useSmallScreen();

  const aggregatedQueries = useMemo(() => {
    return [...userSpecificQueries, ...listingGeneralQueries];
  }, [listingGeneralQueries, userSpecificQueries]);

  const requestDirections = useCallback(async () => {
    setIsLoading();
    const promises: Promise<void>[] = [];
    for (const query of aggregatedQueries) {
      promises.push(query.getDirections(assertDefined(REFERENCE_PLACE.location)));
    }
    await Promise.allSettled(promises);
    setIsNotLoading();
  }, [aggregatedQueries, setIsLoading, setIsNotLoading]);

  const onQueryChosenForSidepanel = useCallback(
    (key: string) => {
      const query = aggregatedQueries.find((x) => x.key === key);
      setQueryForSidepanel(query);
    },
    [aggregatedQueries]
  );

  useEffect(() => {
    setPreferredTransportationMode(TransportationMode.PUBLIC_TRANSPORTATION);
    sleep(500).then(requestDirections);
  }, [requestDirections, setPreferredTransportationMode]);

  return (
    <ErrorBoundary>
      <div className="main-page-container">
        <PanelGroup autoSaveId="mainPagePanelGroup" direction="horizontal">
          <Panel>
            <div className="controls-sidepanel-parent">
              <Text size={29}>{REFERENCE_PLACE.location?.name}</Text>
              <Space h="sm" />
              <div>
                <Button
                  component="a"
                  href={REFERENCE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Show in StreetEasy
                </Button>
              </div>
              <Space h="md" />

              {isLoading && (
                <div>
                  <LoadingIndicator shouldShow />
                </div>
              )}

              {!isLoading && (
                <div className="sales-demo-query-list">
                  <Box bg={"gray.3"} w="100%" mb={16} className="rounded row flex items-center">
                    <Title order={3} className="px-2">
                      Your Places
                    </Title>
                    <TouchFriendlyTooltip label="These are the places you've saved." withinPortal>
                      <span className="h-100 flex items-center">
                        <FiInfo />
                      </span>
                    </TouchFriendlyTooltip>
                  </Box>

                  {userSpecificQueries.map((option) => (
                    <ErrorBoundary key={option.key}>
                      <div className="col query-list-element">
                        <SalesDemoQueryListElement
                          query={option}
                          onQueryChosenForSidepanel={onQueryChosenForSidepanel}
                          star
                        />
                        <Space h="sm" />
                      </div>
                    </ErrorBoundary>
                  ))}

                  <Space h="sm" />

                  <Box bg={"gray.3"} w="100%" mb={16} className="rounded row flex items-center">
                    <Title order={3} className="px-2">
                      Other highlights
                    </Title>
                    <TouchFriendlyTooltip
                      label="These are places the realtor wants you to know about."
                      withinPortal
                    >
                      <span className="h-100 flex items-center">
                        <FiInfo />
                      </span>
                    </TouchFriendlyTooltip>
                  </Box>

                  {listingGeneralQueries.map((option) => (
                    <ErrorBoundary key={option.key}>
                      <div className="col query-list-element">
                        <SalesDemoQueryListElement
                          query={option}
                          onQueryChosenForSidepanel={onQueryChosenForSidepanel}
                        />
                        <Space h="sm" />
                      </div>
                    </ErrorBoundary>
                  ))}
                </div>
              )}
            </div>
          </Panel>

          {!smallScreen && (
            <>
              <StandardPanelResizeHandle />
              <Panel defaultSize={25} maxSize={70} minSize={10} className="map-sidepanel">
                <EmbeddedMap
                  height={"100%"}
                  width={"100%"}
                  origin={queryForSidepanel?.route?.origin ?? REFERENCE_PLACE.location}
                  destination={queryForSidepanel?.route?.destination}
                  modeOfTransport={getPreferredModeOfTransport()}
                />
              </Panel>
            </>
          )}
        </PanelGroup>
      </div>
    </ErrorBoundary>
  );
};

export const SalesDemoDistanceMatrixPage = makePage(
  SalesDemoDistanceMatrixPageImpl,
  TAB_NAME_BASE + "Distance Matrix Demo",
  { hideLocationUI: true }
);
