import { Modal, Title, Text, Stack } from "@mantine/core";
import { noop } from "utils/misc";
import { LoadingIndicator } from "./LoadingIndicator";
import { AdSenseAd } from "ads/AdSense";

interface LoadingModalProps {
  open: boolean;
}

export const LoadingModal = (props: LoadingModalProps) => {
  return (
    <Modal.Root centered opened={props.open} onClose={noop}>
      <Modal.Overlay blur={3} />
      <Modal.Content>
        <Modal.Header>
          <Title order={3}>Working on it...</Title>
        </Modal.Header>
        <Modal.Body>
          <Stack align="center">
            <LoadingIndicator shouldShow size={40} />
            <AdSenseAd
              client={"ca-pub-3826030519001321"}
              slot="5168492812"
              style={{ width: 300, height: 300 }}
            />
            <Text align="center">
              This could take a couple minutes.
              <br />
              Get filled with anticipation!
            </Text>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
