import { Autocomplete, CloseButton, Divider, type SelectItemProps, Text } from "@mantine/core";
import { CloudFunctions } from "apis/internal/cloudFunctions";
import { GeoMasterName } from "geomasters/geomaster";
import { getCurrentGeoMaster } from "geomasters/geomasterGetter";
import React, { forwardRef, useEffect } from "react";
import { FiInfo } from "react-icons/fi";
import type { Coordinates, GeographicPlace, PartialGeographicPlace } from "types/geo";
import type { Nullable, Undefined } from "types/utils";
import { logError } from "utils/errors";
import { metersToMiles, useUserCoordinates } from "utils/geo";
import { assertDefined } from "utils/misc";
import { MEDIUM_TIMEOUT, isTimedPromiseTimeoutError, timedPromise } from "utils/promises";
import { extractURLFromString } from "utils/urls";
import { v4 as uuidv4 } from "uuid";
import { LoadingIndicator } from "./LoadingIndicator";
import { TouchFriendlyTooltip } from "./TouchFriendlyTooltip";

import "./PlaceAutocompleteTextInput.css";
import {
  getHasSeenOneTimeTooltip,
  recordHasSeenOneTimeTooltip,
} from "storage/localStorageAccessors";
import haversineDistance from "haversine-distance";

interface UserLocationProviderProps {
  onLocation: (coords: Coordinates) => void;
}

const UserLocationProvider = (props: UserLocationProviderProps) => {
  const { onLocation } = props;
  const location = useUserCoordinates();

  useEffect(() => {
    location && onLocation(location);
  }, [location, onLocation]);

  return null;
};

const DEFAULT_PLACEHOLDER = "Enter a name, address, or listing URL.";
const CHAR_LIMIT_FOR_AUTO_COMPLETE = 3;
const AUTOCOMPLETE_DEBOUNCING_LIMIT_MS = 300;

enum AutocompleteOptionType {
  STANDARD,
  LISTING_URL,
}

interface AutocompleteOptionBase extends SelectItemProps {
  type: AutocompleteOptionType;
}

interface StandardAutocompleteOption extends PartialGeographicPlace, AutocompleteOptionBase {
  type: AutocompleteOptionType.STANDARD;
  value: string;
  isAttestation?: boolean;
  disabled?: boolean;
}

interface ListingURLAutocompleteOption extends AutocompleteOptionBase {
  type: AutocompleteOptionType.LISTING_URL;
  value: string;
  disabled?: boolean;
}

type AutocompleteOption = StandardAutocompleteOption | ListingURLAutocompleteOption;

const SUPPORTED_LISTING_URL_HOSTNAMES = ["streeteasy.com"];

interface PlacesAutocompleteTextInputProps {
  onLocationChosen: (s: GeographicPlace) => void;
  searchBarPlaceholder?: string;
  errorMessage?: string;
  initialTextValue?: string;
  label?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

interface PlacesAutocompleteTextInputState {
  textInput: string;
  userCoordinates: Coordinates | null;
  autocompleteData: AutocompleteOption[];
  errorMessage: string;
  isLoadingFromAutocompleteOptionsRequest: boolean;
  isLoadingFromPlaceDetailsRequest: boolean;
}

export default class PlacesAutocompleteText extends React.PureComponent<
  PlacesAutocompleteTextInputProps,
  PlacesAutocompleteTextInputState
> {
  geoMaster = getCurrentGeoMaster();
  currentAutocompleteInvocationId: NodeJS.Timeout | null = null;
  innerTooltipRef = React.createRef<HTMLSpanElement>();

  state: PlacesAutocompleteTextInputState = {
    textInput: this.props.initialTextValue || "",
    userCoordinates: null,
    autocompleteData: [],
    errorMessage: "",
    isLoadingFromAutocompleteOptionsRequest: false,
    isLoadingFromPlaceDetailsRequest: false,
  };

  updateUserLocation = (pos: Coordinates): void => {
    this.setState({
      userCoordinates: {
        latitude: pos.latitude,
        longitude: pos.longitude,
      },
    });
  };

  render(): React.ReactNode {
    return (
      <div className="col flex-grow">
        <div className="row w100 gap-1">
          <UserLocationProvider onLocation={this.updateUserLocation} />
          <Autocomplete
            inputContainer={(children) => (
              <span className="row w100">
                <div className="flex-1 mr-1">{children}</div>
                <TouchFriendlyTooltip
                  label="Don't worry if the text is different than the option you choose - it's the same coordinates."
                  innerTooltipRef={this.innerTooltipRef}
                  multiline
                  w={300}
                >
                  <span className="flex">
                    <FiInfo />
                  </span>
                </TouchFriendlyTooltip>
              </span>
            )}
            label={this.props.label}
            data={this.state.autocompleteData}
            itemComponent={AutoCompleteItemComponent}
            onChange={this.onSearchBarValueChange}
            value={this.state.textInput}
            placeholder={this.props.searchBarPlaceholder ?? DEFAULT_PLACEHOLDER}
            error={this.state.errorMessage}
            onItemSubmit={this.onAutocompleteOptionClicked}
            nothingFound={
              this.state.textInput ? <Text>No autocomplete suggestions yet.</Text> : null
            }
            rightSection={this.renderRightSection()}
            filter={this.noFilter}
            limit={10}
            onKeyDown={this.onSearchBarKeyDown}
            disabled={this.state.isLoadingFromPlaceDetailsRequest || this.props.disabled}
            withinPortal
            autoFocus={this.props.autoFocus}
          />
        </div>
      </div>
    );
  }

  noFilter = () => true;

  updateWithLocation = async (provider: () => Promise<Nullable<GeographicPlace>>) => {
    try {
      // This "Loading..." is here to force the input component to rerender with a value that makes sense.
      // If it's not here, then the input automatically (not my code - mantine does this)
      // chooses the id of the option you just selected as the Input's value
      this.setState({ isLoadingFromPlaceDetailsRequest: true, textInput: "Loading..." });
      const location = await provider();
      if (location) {
        this.setState({ autocompleteData: [] });
        this.setSearchBarValue(location.name);
        this.clearErrorFeedback();
        this.props.onLocationChosen(location);
        if (!getHasSeenOneTimeTooltip("autocomplete explanation")) {
          this.innerTooltipRef.current?.click();
          recordHasSeenOneTimeTooltip("autocomplete explanation");
        }
      } else {
        this.provideErrorFeedback("Couldn't find a place!");
      }
    } catch (e) {
      this.provideErrorFeedback(e);
      logError(e);
    } finally {
      this.setState({ isLoadingFromPlaceDetailsRequest: false });
    }
  };

  updateWithLocationFromUrl = async () => {
    const locationProvider: () => Promise<Nullable<GeographicPlace>> = async () => {
      const addressInformation = await CloudFunctions.ApiGetAddressFromListingURL({
        url: this.state.textInput,
      });
      const place: GeographicPlace = {
        name: addressInformation.name,
        coords: addressInformation.coordinates,
        timestampInMillis: Date.now(),
        geoMasterName: GeoMasterName._SERVER_,
      };
      return place;
    };

    this.updateWithLocation(locationProvider);
  };

  onAutocompleteOptionClicked = async (place: AutocompleteOption): Promise<void> => {
    if (place.type === AutocompleteOptionType.STANDARD) {
      const locationProvider = () => this.geoMaster.getPlaceForAutocompleteUsingPartial(place);
      this.updateWithLocation(locationProvider);
    } else {
      this.updateWithLocationFromUrl();
    }
  };

  onSearchBarKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (
      e.key !== "Enter" ||
      (e.target instanceof Element && e.target.hasAttribute("aria-activedescendant"))
    ) {
      return;
    }

    if (e.target instanceof HTMLElement) {
      e.target.blur();
    }

    if (extractURLFromString(this.state.textInput)) {
      this.updateWithLocationFromUrl();
    } else {
      const locationProvider = () =>
        this.geoMaster.getPlaceForAutocompleteUsingQuery(
          this.state.textInput,
          this.state.userCoordinates
        );
      this.updateWithLocation(locationProvider);
    }
  };

  renderRightSection = (): React.ReactElement | null => {
    const isLoading =
      this.state.isLoadingFromAutocompleteOptionsRequest ||
      this.state.isLoadingFromPlaceDetailsRequest;
    if (!isLoading) {
      return <CloseButton onClick={this.clearInput} />;
    } else {
      // TODO: this currently doesn't show when isLoadingFromDetailsRequest
      // is true because that also disables the Input component, and in mantine v6
      // the disabled Input does't show the right section. This is fixed in Mantine v7
      return <LoadingIndicator shouldShow size={20} />;
    }
  };

  clearInput = () => {
    this.setState({ autocompleteData: [] });
    this.setSearchBarValue("");
  };

  onSearchBarValueChange = (newValue: string): void => {
    this.setSearchBarValue(newValue);

    if (extractURLFromString(newValue)) {
      this.getListingURLOptions(newValue);
    } else {
      if (newValue.trim().length >= CHAR_LIMIT_FOR_AUTO_COMPLETE) {
        this.getAutoCompleteOptionsDebounced(newValue);
      }
    }
  };

  // Debouncing is to prevent excessive fetches when the user is typing fast)
  getAutoCompleteOptionsDebounced = (query: string): void => {
    if (this.currentAutocompleteInvocationId) {
      clearTimeout(this.currentAutocompleteInvocationId);
    }

    this.currentAutocompleteInvocationId = setTimeout(() => {
      this.setState({ isLoadingFromAutocompleteOptionsRequest: true });
      this.clearErrorFeedback();
      timedPromise(
        (async (): Promise<PartialGeographicPlace[] | null> => {
          const predictions = await this.geoMaster.getAutocompleteFromQuery(
            query,
            this.state.userCoordinates
          );
          return predictions;
        })(),
        MEDIUM_TIMEOUT
      )
        .then((pred) => {
          const mappedPredictions: Undefined<StandardAutocompleteOption[]> = pred?.map((x) => ({
            ...x,
            label: x.name,
            value: x.geoMasterProvidedId ?? uuidv4(),
            type: AutocompleteOptionType.STANDARD,
          }));

          if (mappedPredictions && mappedPredictions.length > 0) {
            mappedPredictions.push({
              label: "ATTESTATION",
              value: "ATTESTATION",
              isAttestation: true,
              geoMasterName: GeoMasterName._DUMMY_,
              disabled: true,
              type: AutocompleteOptionType.STANDARD,
            });
            this.setState({ autocompleteData: mappedPredictions });
          }
        })
        .catch((e) => {
          logError(e);
          this.provideErrorFeedback(e);
        })
        .finally(() => {
          this.currentAutocompleteInvocationId = null;
          this.setState({ isLoadingFromAutocompleteOptionsRequest: false });
        });
    }, AUTOCOMPLETE_DEBOUNCING_LIMIT_MS);
  };

  getListingURLOptions = (query: string): void => {
    if (this.currentAutocompleteInvocationId) {
      clearTimeout(this.currentAutocompleteInvocationId);
    }

    const url = assertDefined(extractURLFromString(query));

    if (SUPPORTED_LISTING_URL_HOSTNAMES.includes(url.hostname)) {
      this.setState({
        autocompleteData: [
          {
            type: AutocompleteOptionType.LISTING_URL,
            value: query,
            label: `Get address from ${url.hostname}`,
          },
        ],
      });
    } else {
      this.setState({
        autocompleteData: [
          {
            type: AutocompleteOptionType.LISTING_URL,
            value: query,
            disabled: true,
            label: `${url.hostname} addresses not supported at the moment.`,
          },
        ],
      });
    }
  };

  clearErrorFeedback = (): void => {
    this.setState({ errorMessage: "" });
  };

  provideErrorFeedback = (e: any): void => {
    let message = "";
    if (typeof e === "string") {
      message = e;
    }
    if (isTimedPromiseTimeoutError(e)) {
      message = "Timeout!";
    }
    if (e.message) {
      message = e.message;
    }
    this.setState({ errorMessage: message });
  };

  setSearchBarValue = (val: string): void => {
    this.setState({ textInput: val });
  };
}

const StandardAutoCompleteItemComponent = forwardRef<HTMLDivElement, StandardAutocompleteOption>(
  (
    {
      name,
      distanceToUsersInMeters,
      subtext,
      geoMasterProvidedId,
      geoMasterName,
      isAttestation,
      coords,
      ...others
    }: StandardAutocompleteOption,
    ref
  ) => {
    const userCoords = useUserCoordinates();
    let resolvedDistanceInMeters = distanceToUsersInMeters;
    if (!resolvedDistanceInMeters && userCoords && coords) {
      resolvedDistanceInMeters = haversineDistance(userCoords, coords);
    }

    return (
      <div ref={ref} {...others}>
        {isAttestation ? (
          getCurrentGeoMaster().getAttestationForAutocomplete()
        ) : (
          <>
            <Text className="autosuggest-title">{name}</Text>
            <Text>
              {resolvedDistanceInMeters && (
                <Text span className="autosuggest-subtitle">
                  {`(${metersToMiles(resolvedDistanceInMeters).toFixed(2)}mi)`}
                </Text>
              )}
              {subtext && <Text span className="autosuggest-distance">{` - ${subtext}`}</Text>}
            </Text>
            <Divider />
          </>
        )}
      </div>
    );
  }
);

const ListingURLAutoCompleteItemComponent = forwardRef<
  HTMLDivElement,
  ListingURLAutocompleteOption
>(({ value, label, ...others }: ListingURLAutocompleteOption, ref) => (
  <div ref={ref} {...others}>
    <Text>{label}</Text>
  </div>
));

const AutoCompleteItemComponent = forwardRef<HTMLDivElement, AutocompleteOption>(
  (props: AutocompleteOption, ref) => {
    if (props.type === AutocompleteOptionType.LISTING_URL) {
      return <ListingURLAutoCompleteItemComponent {...props} ref={ref} />;
    } else {
      return <StandardAutoCompleteItemComponent {...props} ref={ref} />;
    }
  }
);
