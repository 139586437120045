import { ActionIcon, Text, Tooltip } from "@mantine/core";
import { ButtonPanel } from "common_ui/ButtonPanel";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import {
  AddressSpecificQuery,
  type OpenQuery,
  type Query,
} from "pages/distance_matrix/locationQueryTypes";
import { RouteRenderer } from "pages/distance_matrix/RouteRenderer";
import { memo, useCallback } from "react";
import { FiLayout, FiMapPin, FiMaximize2, FiMinimize2, FiStar } from "react-icons/fi";
import type { GeographicPlace } from "types/geo";
import type { Undefined } from "types/utils";
import { useToggleState } from "utils/hooks";
import { noop } from "utils/misc";

interface QueryComponentProps {
  query: Query;
  onQueryChosenForSidepanel: (key: string) => void;
  star?: boolean;
}

export const SalesDemoQueryListElement = memo((props: QueryComponentProps) => {
  const { query } = props;
  const smallScreen = useSmallScreen();

  let input = undefined;
  if (query instanceof AddressSpecificQuery) {
    input = (
      <DisabledAddressSpecificQueryInput onChange={noop} initialValue={query.location?.name} />
    );
  } else {
    input = <DisabledOpenQueryInput onChange={noop} query={query} />;
  }

  const onChosenForSidepanel = useCallback(() => {
    props.onQueryChosenForSidepanel(query.key);
  }, [props, query]);

  const [expanded, toggleExpansion] = useToggleState(false);

  return (
    <div>
      <div className="flex row !items-start">
        <div>
          <Text size={"lg"} mt={4} mr={4}>
            {props.star ? <FiStar /> : <FiMapPin />}
          </Text>
        </div>
        <div className="row w100 !items-center gap-1">
          {input}
          <div className="flex-1" />
          <ButtonPanel span>
            {query.route && !smallScreen && (
              <Tooltip label={"Link to Sidepanel"} withArrow>
                <ActionIcon onClick={onChosenForSidepanel} color="primary" variant="light">
                  <FiLayout />
                </ActionIcon>
              </Tooltip>
            )}
            {query.route && (
              <Tooltip label={expanded ? "Shrink" : "Expand"} withArrow>
                <ActionIcon onClick={toggleExpansion} color="primary" variant="light">
                  {expanded ? <FiMinimize2 /> : <FiMaximize2 />}
                </ActionIcon>
              </Tooltip>
            )}
          </ButtonPanel>
        </div>
      </div>

      <div className="pl-8 pt-1">
        <RouteRenderer route={query.route} expanded={expanded} />
      </div>
    </div>
  );
});

const DisabledOpenQueryInput = (props: {
  query: OpenQuery;
  onChange: (val: Undefined<string>) => void;
}) => {
  return (
    <Text size={"lg"}>
      Nearest <b>{props.query.textQuery}</b>
      <Text c="dimmed" size={"sm"}>
        {props.query.route?.destination?.longAddress ?? props.query.route?.destination?.name}
      </Text>
    </Text>
  );
};

const DisabledAddressSpecificQueryInput = (props: {
  initialValue: Undefined<string>;
  onChange: (location: GeographicPlace) => void;
}) => {
  return (
    <Text size={"lg"}>
      <b>{props.initialValue}</b>
    </Text>
  );
};
